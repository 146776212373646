import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupCarouselComponent } from './popup-carousel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    PopupCarouselComponent
  ],
  imports: [
    CommonModule,NgbModule
  ],
  exports:[PopupCarouselComponent, NgbModule]
})
export class PopupCarouselModule { }
