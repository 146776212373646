import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VMPropertyNotification } from '../../../_VMClasses/propertylist/VMPropertyNotification';
import { PropertylistAdminService } from '../../../../core/services/PageServices/propertylist-admin.service';


@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {
  @Input() public data;
  _VMPropertyNotification: VMPropertyNotification[];
  constructor(public activeModal: NgbActiveModal,private propertyService: PropertylistAdminService) { }

  ngOnInit(): void {
    this.propertyService.getPropertyNotificationbypropid(this.data).subscribe((arg : any) => {
      this._VMPropertyNotification = arg;
      console.log(this._VMPropertyNotification);
    });

    //console.log(this.data);
  }

  updateNotification(data)
  {
    console.log(JSON.stringify(data));
    data.IsRead = true;
    this.propertyService.UpdatePropertyNoification(JSON.stringify(data)).subscribe((i: string)=>{
    console.log(i);
    });
  }

}
