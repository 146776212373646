<div class="modal-header">
    <h5 class="modal-title" id="contactModalLabel">Notification</h5>
    <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <div class="row note" >
        <div class="col-12 col-sm-12 col-lg-12" *ngFor="let notify of _VMPropertyNotification; let i = index">

            <div class="card p-3 m-3">
                <div class="col-xl-12">
                   
                    <div class="property-agent-name">
                        <p>
                            <b>Name: {{ notify.Name }}</b>
                        </p>
                        <p><b>Email : {{notify.Email}}</b></p>
                        <p><b>Phone : {{notify.Phone}}</b></p>
                        <div *ngIf="!notify.IsRead" >
                        <button (click)="updateNotification(notify)" class="btn btn-blue btn-notification">Read Message</button>
                    </div>
                        <div  *ngIf="notify.IsRead"><p>Message : {{notify.Message}}</p></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">
        close
    </button> -->
    <!-- <button type="button" class="btn btn-primary">Send Message</button> -->
</div>