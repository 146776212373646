import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { VMProperty } from 'src/app/pages/_VMClasses/propertylist/VMPropertyModel';
//import { environment } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../GlobalServices/global.service';

const API_ROOT_URL = `${environment.apiUrl}`;
const IMG_ROOT_URL = `${environment.imgUrl}`;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private _global: GlobalService) { }

  postUserRegistration(postData: any) {

    return this.http.post<string>(`${API_ROOT_URL}/api/Account/UserRegister`, postData);
  }

  upadteUserDetails(postData: any) {
    const userToken = this._global.getAuthFromLocalStorage('authLocalStorageToken');
    //  let httpHeaders = new HttpHeaders();
    //  httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken.access_token);
    //  httpHeaders.append('Content-Type' , 'application/json');
    var httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken.access_token
    });
    console.log(httpHeaders);
    return this.http.put<string>(`${API_ROOT_URL}/api/User/putUserByToken`,
     postData, { headers: httpHeaders });
  }
  getprofileimgage(mediaID: string):any {
    let prms = new HttpParams();
    prms = prms.append('mediaId', mediaID);
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(`${API_ROOT_URL}/api/User/getprofileimgage`,{ params: prms } );   
  }
  GetForgetPassword(email: string,lan:string): Observable<any> {
    let prms = new HttpParams();
    prms = prms.append('email', email);
    prms = prms.append('lan', lan);
    return this.http.get<any>(
        `${API_ROOT_URL}/api/Account/GetUserInfoforgetPsw`,
        { params: prms }
    );
    // return this.http.post<VMProperty[]>( `${API_ROOT_URL}api/Property/propertybymap`, poly );
}

ResetUserPassword(postData: any) {
  return this.http.post<string>(`${API_ROOT_URL}/api/Account/ResetPassword`, postData);
 }
 ConfirmEmailGet(userId: string,code: string,lan:string): Observable<any> {
  let prms = new HttpParams();
  prms = prms.append('userId', userId);
  prms = prms.append('code', code);
  prms = prms.append('lan', lan);
  return this.http.get<any>(
      `${API_ROOT_URL}/api/Account/ConfirmEmail`,
      { params: prms }
  );
  // return this.http.post<VMProperty[]>( `${API_ROOT_URL}api/Property/propertybymap`, poly );
}
   ////////////////////////////////////////////////////////
  ///////////////// Strat: Profile Images //////////////////
  ////////////////////////////////////////////////////////

  onProfileImgSelect(userid: string,files: File[],command: string) 
 // onProfileImgSelect(files: File[]) 
  {
    //let prms = new HttpParams();
    const formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    console.log(files);
    console.log(formData);

    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>(`${IMG_ROOT_URL}/api/Values/Postprofilepic`,
      formData).pipe(
        map((res: any) => {
          //const result = [
           // {
            //  "PropertyID": propid,
            //  "FloorNumber": FloorNumber,
             // "Medium": {
              //  "ID": res.ID,
              //  "Name": res.Name,
              //  "Path": res.Path
            //  }
            //}]
            console.log(res);
            const result = 
            {
                "ID": res[0].ID,
                "Name": res[0].Name,
                "Path": res[0].Path
              }
            
          console.log(result);
          //this.uploadprofileImg(result)
          return result;
        }),
        switchMap((response: any) => this.uploadprofileImg(
          response)),
        catchError((err) => {
         console.error('err', err);
          return of(err);
      }),
        finalize(() => { })
      
        );
       
  }
  uploadprofileImg(data): any {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<any>(`${API_ROOT_URL}/api/User/postprofileImgback`, data);
    
  }
  deleteProfileImg(mediaID: string): Observable<number> {
    let prms = new HttpParams();
    prms = prms.append('mediaId', mediaID);

    return this.http.get<number>(`${API_ROOT_URL}/api/User/deleteprofileimages`,
      { params: prms });
  }

  ////////////////////////////////////////////////////////
  ////////////////// End: Profile Images ///////////////////
  ////////////////////////////////////////////////////////

}